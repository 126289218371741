import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { Tag } from 'primereact/tag';
import { format } from 'date-fns';
import HeaderComponent from '../../components/Shared/Header/header.component';
import { getNutritionistsSales } from '../../api/nutritionistSalesApi';
import { convertStringToMoneyFormat, getSession } from '../../utils/helpers';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';

interface Product {
  id: string;
  date: Date;
  customer: string;
  channel: string;
  total: string;
  status: string;
  quantity: number;
  specialist: string;
  specialistUrl: string;
  totalNumber: number;
  totalDiscounts: number;
  discountCode: string;
}

const SalesScreen = () => {
  const [loading, setLoading] = useState(true);
  const [sessionToken, setSessionToken] = useState<string>('');
  const [products, setProducts] = useState<Product[]>([]);
  const [allNutritionists, setAllNutritionists] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [filters, setFilters] = useState<{
    [key: string]: { value: string | null; matchMode: FilterMatchMode };
  }>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    date: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    customer: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    channel: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    total: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    quantity: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    specialist: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    specialistUrl: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });
  const [dateSelected, setDateSelected] = useState<string>('');

  const getSeverity = (status: any) => {
    switch (status) {
      case 'paid':
        return 'info';
      case 'pending':
        return 'warning';
    }
  };

  // having as input month and year, return first and last day of the month as date
  const getStartAndEndDate = (month: number, year: number) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    return { startDate, endDate };
  };

  const fetchSales = async (
    sessionToken: string,
    startDate: Date,
    endDate: Date
  ) => {
    setLoading(true);
    const res = await getNutritionistsSales(
      '',
      250,
      'shopifyNutritionistOrder',
      sessionToken || '',
      startDate.toString(),
      endDate.toString()
    );

    if (res && res.orders.length > 0) {
      const _products: Product[] = [];
      res.orders.forEach((item) => {
        const tags = item.tags.split(',');
        const nutritionistId = tags.find((tag) =>
          tag.includes('nutritionistId')
        );
        const nutritionistTag = tags.find((tag) =>
          tag.includes('nutritionistUsername')
        );
        const _product: Product = {
          id: `${item.name}`,
          date: new Date(item.created_at),
          customer: `${item?.customer?.first_name || ''} ${
            item?.customer?.last_name || ''
          }`,
          channel: item.source_name,
          total: convertStringToMoneyFormat(item.total_price),
          status: item.financial_status,
          quantity: item.line_items.length,
          specialist: nutritionistTag?.split(':')[1] || '',
          specialistUrl: `/user-outside-detail/${
            nutritionistId?.split(':')[1]
          }`,
          totalNumber: parseFloat(item.total_price),
          totalDiscounts: parseFloat(item.total_discounts || '0') || 0,
          discountCode: item.discount_codes[0]?.code || '',
        };
        const nutritionistNames = res.orders
          .map((order) => {
            const tags = order.tags.split(',');
            const nutritionistTag = tags.find((tag) =>
              tag.includes('nutritionistUsername')
            );
            return nutritionistTag?.split(':')[1] || '';
          })
          .filter(
            (name, index, array) => name && array.indexOf(name) === index
          );

        setAllNutritionists(nutritionistNames);
        _products.push(_product);
      });
      setProducts(_products);
      setLoading(false);
    } else {
      setLoading(false);
      setProducts([]);
    }
  };

  useEffect(() => {
    const session = getSession();
    if (!session || !session?.sessionToken) {
      window.location.href = '/';
    }
    setSessionToken(session?.sessionToken || '');
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const { startDate, endDate } = getStartAndEndDate(
      currentMonth,
      currentYear
    );
    setDateSelected(formatMonthYear(startDate));
    fetchSales(session?.sessionToken || '', startDate, endDate);
  }, []);

  const search = (event: any) => {
    let filteredSuggestions: string[]; // Asegúrate de que es un array de strings

    if (event.query.length === 0) {
      filteredSuggestions = [...allNutritionists]; // allNutritionists debe ser string[]
    } else {
      filteredSuggestions = allNutritionists.filter((name) =>
        name.toLowerCase().includes(event.query.toLowerCase())
      );
    }

    setSuggestions(filteredSuggestions);
  };

  const initFilters = () => {
    setFilters({
      ...filters,
      date: { value: null, matchMode: FilterMatchMode.DATE_IS },
    });
  };

  const renderHeader = () => {
    return (
      <div className="col-md-4 col-12 col-reset">
        <div className="search-bar">
          <span className="material-icons-outlined icon">search</span>
          <AutoComplete
            value={value}
            suggestions={suggestions}
            completeMethod={search}
            onChange={(e) => setValue(e.value)}
            placeholder="Buscar por nombre de nutriólogo..."
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    const _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    initFilters();
    // Agrega cualquier otra lógica necesaria aquí
  }, [value]);

  const header = renderHeader();
  const statusBody = (rowData: any) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };
  const specialistBody = (rowData: any) => {
    return (
      <Link to={rowData.specialistUrl}>
        <strong>{rowData.specialist}</strong>
      </Link>
    );
  };

  const formatDateDisplay = (date: Date) => {
    if (isNaN(date.getTime())) {
      // Comprueba que la fecha es válida
      return 'Fecha no válida';
    }
    return format(date, 'dd/MM/yyyy');
  };

  const dateBodyTemplate = (rowData: any) => {
    return formatDateDisplay(rowData.date);
  };

  const dateFilterTemplate = (options: any) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: any) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const formatMonthYear = (date: Date) => {
    if (!date || isNaN(date.getTime())) {
      // Comprueba que la fecha es válida
      return 'Fecha no válida';
    }
    return format(date, 'yyyy-MM');
  };

  // render a list of buttons for the last 3 months with format yyyy-MM
  const renderMonthButtons = () => {
    const months = [];
    for (let i = 0; i < 4; i++) {
      const date = new Date();
      date.setMonth(date.getMonth() - i);
      months.push(formatMonthYear(date));
    }
    return months.map((month) => (
      <button
        key={month}
        className={`btn btn-sm ${
          dateSelected === month ? 'btn-primary' : 'btn-outline-primary'
        }`}
        onClick={() => {
          const [year, m] = month.split('-');
          const { startDate, endDate } = getStartAndEndDate(
            parseInt(m, 10) - 1,
            parseInt(year, 10)
          );
          fetchSales(sessionToken, startDate, endDate);
          setDateSelected(month);
        }}
      >
        {month}
      </button>
    ));
  };

  return (
    <>
      <HeaderComponent />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <section className="card-layout">
                  <h1 className="mb-3 h3">Pedidos</h1>
                  <div className="mb-3 d-flex justify-content-between">
                    <div className="btn-group dashboard-data-button">
                      {renderMonthButtons()}
                    </div>
                  </div>
                  <div className="dashboard-data-extra row">
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de ventas: </h2>
                      <p className="color-green700">
                        <strong>
                          {convertStringToMoneyFormat(
                            products
                              .reduce(
                                (acc, product) => acc + product.totalNumber,
                                0
                              )
                              .toString()
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de artículos: </h2>
                      <p className="color-green700">
                        <strong>
                          {products.reduce(
                            (acc, product) => acc + product.quantity,
                            0
                          )}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-4 col-12">
                      <h2 className="mb-0 h4">Total de pedidos: </h2>
                      <p className="color-green700">
                        <strong>{products.length}</strong>
                      </p>
                    </div>
                  </div>

                  {!loading && products.length && (
                    <DataTable
                      value={products}
                      tableStyle={{ minWidth: '50rem' }}
                      paginator
                      rows={50}
                      loading={loading}
                      header={header}
                      filters={filters}
                      size={'small'}
                      stripedRows
                      scrollable
                      scrollHeight="400px"
                      globalFilterFields={['specialist']}
                    >
                      <Column field="id" header="Pedido" sortable></Column>
                      <Column
                        field="date"
                        header="Fecha"
                        dataType="date"
                        filterField="date"
                        style={{ minWidth: '10rem' }}
                        body={dateBodyTemplate}
                        filter
                        filterElement={dateFilterTemplate}
                        sortable
                        showFilterMenu={true}
                        showAddButton={true}
                        maxConstraints={2}
                      ></Column>
                      <Column
                        field="date"
                        header="Mes"
                        body={(rowData) => formatMonthYear(rowData.date)}
                      ></Column>
                      <Column
                        field="customer"
                        header="Cliente"
                        sortable
                      ></Column>
                      <Column field="channel" header="Canal" sortable></Column>
                      <Column
                        field="totalDiscounts"
                        header="Descuentos"
                        sortable
                        body={(rowData) =>
                          convertStringToMoneyFormat(
                            rowData.totalDiscounts.toString()
                          )
                        }
                      ></Column>
                      <Column
                        field="discountCode"
                        header="Código de descuento"
                        sortable
                      ></Column>
                      <Column field="total" header="Total" sortable></Column>
                      <Column
                        field="status"
                        header="Estado del pago"
                        sortable
                        body={statusBody}
                      ></Column>
                      <Column
                        field="quantity"
                        header="Artículos"
                        sortable
                      ></Column>
                      <Column
                        field="specialist"
                        header="Nutriólogo"
                        sortable
                        body={specialistBody}
                      ></Column>
                    </DataTable>
                  )}
                </section>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SalesScreen;
